import "./App.css";

function Nav() {
  return (
    <ul className="Nav-ul">
      <li className="Nav-li">
        <a class="active" href="/">
          O . S
        </a>
      </li>
    </ul>
  );
}

function Footer(props) {
  return (
    <footer className="App-footer">
      <p>Oli Smith {props.year}, In progress</p>
    </footer>
  );
}

const projects = [
  [
    "Automatic Data Labelling API",
    "https://github.com/olismith0/Yolo3_with_GUI",
  ],
  [
    "Disk Analysis Script",
    "https://github.com/olismith0/Bash_Disk_Analysis_Script",
  ],
  ["Caesar Decoder", "https://github.com/olismith0/Caesar-Decoder"],
];

function Main(props) {
  return (
    <main>
      <div className="App">
        <title>Portfolio Page</title>
        <Nav />
        <header className="App-header">
          <section className="App-Section">
            <ul>
              {projects.map((project) => (
                <a href={project[1]}>
                  <li>{project[0]}</li>
                </a>
              ))}
            </ul>
          </section>
        </header>
        <Footer year={new Date().getFullYear()} />
      </div>
    </main>
  );
}

function App() {
  return <Main />;
}
export default App;
